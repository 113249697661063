.notifications-container {
	position: relative;
}

.dropdown-menu {
	position: absolute;
	top: 40px;
	/* Adjust based on your layout */
	right: 0;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 6px;
	box-shadow: 0 2px 10px rgba(48, 48, 48, 0.1);
	width: 350px;
	/* Adjust width as needed */
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 1000;
}

.dropdown-menu-header {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	background-color: #f1f1f1;
	border-bottom: 1px solid #ccc;
	position: sticky;
	top: 0;
	z-index: 1001; /* Ensure the header is above other elements in the dropdown */
}

.dropdown-menu-header button {
	background: none;
	border: none;
	color: #658eca;
	cursor: pointer;
	padding: 2px 4px;
	font-size: 20px;
	font-weight: 400;
	transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
}

.dropdown-menu-header button:hover {
	color: #283078;
	font-weight: 500;
}

.notification-item {
	padding: 6px 10px 0 10px;
	border-bottom: 1px solid #eee;
	text-align: right;
}

.notification-item.isNotRead {
	background-color: #dde7f5;
}

.notification-item .not-title {
	font-size: 16px;
	font-weight: 400;
	color: #658eca;
}

.notification-item .not-title.isNotRead {
	font-weight: 500;
	color: #283078;
}

.notification-item .not-body {
	font-size: 16px;
	font-weight: 300;
	color: #30506bff;
	margin-bottom: 8px;
}

.notification-item .not-body.isNotRead {
	font-weight: 400;
	color: #2b3168;
}

.notification-item .not-date {
	font-size: 14px;
	color: #283078;
	text-align: left;
}

.notification-item p {
	margin: 0;
}
